import { createContext } from 'react';

/**
 defaultCommunityRID:sets default RID if not passed in through query parameter
 defaultModelRID: sets default RID if not passed in through query parameter
 touchscreenEnabled: manages UI/data differences b/w touchscreen/web versions
 showPriceOnMenu: displays the price next to each option in the option menu,
 printToS3: save file to s3 bucket or open in new browser tab
 showPriceOnPDF: determines if price is displayed on PDF,
 allowFloorplanZoom: can you zoom in on floorplans,
 nestedUnderCommRidS3: is model folder nested under commRID when creating s3 path,
 colorSelectorEnabled: should color selector be displayed or elevation graphics,
 loadModelRules: should model rules be loaded from modelRuleConfig.js,
 clientFloorOnAppLoad: if not model rules, need to supply default floor to load
 showKovaStage: toggles many settings based on if loading kova graphics,
 multiChoiceOptExclusionList: hide multichoice categories from option list,
 showCommunityDropdownList: show/hide community drop down list in left menu,
 communityDropdownList: list of community RIDs to be shown in dropdown list,
 showElevationSelect: show/hide elevation select in left menu,
 kovaKeyMap: maps static values to kova options names,
 theme: colors/logos etc.
 */

export const settings = {
  oakwood: {
    defaultCommunityRID: 219,
    defaultModelRID: 19288,
    touchscreenEnabled: true,
    showPriceOnMenu: false,
    printToS3: true,
    showPriceOnPDF: true,
    allowFloorplanZoom: false,
    nestedUnderCommRidS3: true,
    colorSelectorEnabled: false,
    loadModelRules: false,
    useKovaPDFDownloader: true,
    clientFloorOnAppLoad: 0,
    showKovaStage: true,
    multiChoiceOptExclusionList: ['REVERSEPLAN'],
    showCommunityDropdownList: false,
    communityDropdownList: [],
    showElevationSelect: true,
    kovaKeyMap: {
      elevation: 'ELEVATION',
      garage: 'GARAGE',
      modelName: 'MarketingName',
      numBeds: 'DescrBedrooms',
      numBaths: 'DescrBaths',
      numSqf: 'DescrSqf',
      optionText: 'Text'
    },
    theme: {
      logo: 'Oakwood_Stacked_White.png',
      primaryColor: '#000',
      viewNameTextTransform: 'capitalize',
      viewNameFontWeight: 500,
      viewToggleArrowImg: 'left_arrow.png',
      progressBarFillColor: '#086EBB'
    }
  },
  goodall: {
    defaultCommunityRID: 271,
    defaultModelRID: 885,
    touchscreenEnabled: true,
    showPriceOnMenu: false,
    printToS3: true,
    showPriceOnPDF: true,
    allowFloorplanZoom: false,
    nestedUnderCommRidS3: true,
    colorSelectorEnabled: false,
    loadModelRules: false,
    useKovaPDFDownloader: true,
    clientFloorOnAppLoad: 0,
    showKovaStage: true,
    multiChoiceOptExclusionList: ['REVERSEPLAN_L'],
    showCommunityDropdownList: false,
    communityDropdownList: [],
    showElevationSelect: false,
    kovaKeyMap: {
      elevation: 'ELEVATION',
      garage: 'GARAGE',
      modelName: 'MarketingName',
      numBeds: 'DescrBedrooms',
      numBaths: 'DescrBaths',
      numSqf: 'DescrSqf',
      optionText: 'Text'
    },
    theme: {
      logo: 'Oakwood_Stacked_White.png',
      primaryColor: '#000',
      viewNameTextTransform: 'capitalize',
      viewNameFontWeight: 500,
      viewToggleArrowImg: 'left_arrow.png',
      progressBarFillColor: '#086EBB'
    }
  },
  arbor: {
    defaultCommunityRID: 218,
    defaultModelRID: 7448,
    touchscreenEnabled: false,
    showPriceOnMenu: false,
    printToS3: false,
    showPriceOnPDF: false,
    allowFloorplanZoom: true,
    nestedUnderCommRidS3: true,
    colorSelectorEnabled: true,
    showKovaStage: false,
    loadModelRules: true,
    useKovaPDFDownloader: false,
    clientFloorOnAppLoad: null,
    multiChoiceOptExclusionList: [],
    showElevationSelect: true,
    kovaKeyMap: {
      elevation: 'ELEV-ELEV',
      garage: 'GARG-GARG',
      modelName: 'Name',
      numBeds: 'DescrBedrooms',
      numBaths: 'DescrBaths',
      numSqf: 'DescrSqf',
      optionText: 'Text'
    },
    showCommunityDropdownList: false,
    communityDropdownList: [
      278,
      287,
      281,
      280,
      283,
      293,
      303,
      292,
      305,
      302,
      304
    ],
    showCityDropdownList: true,
    cityDropdownList: [
      'Indianapolis',
      'Louisville',
      'Columbus',
      'Cincinnati',
      'Dayton'
    ],
    theme: {
      logo: 'arbor_logo.png',
      primaryColor: '#00274D',
      progressBarFillColor: '#00274D',
      viewNameTextTransform: 'uppercase',
      viewNameFontWeight: 600,
      viewToggleArrowImg: 'left_chevron.svg'
    },
  },
  silverthorne: {
    defaultCommunityRID: 219,
    defaultModelRID: 864,
    touchscreenEnabled: false,
    showPriceOnMenu: false,
    printToS3: false,
    showPriceOnPDF: false,
    allowFloorplanZoom: true,
    nestedUnderCommRidS3: true,
    colorSelectorEnabled: false,
    showKovaStage: true, // true will load kova ifp
    loadModelRules: false,
    useKovaPDFDownloader: true,
    clientFloorOnAppLoad: 2,
    multiChoiceOptExclusionList: [],
    showCommunityDropdownList: false,
    communityDropdownList: [
      284,
      270,
      298,
      207,
      276,
      191,
      219
    ],
    showCityDropdownList: true,
    cityDropdownList: [
      'Indianapolis',
      'Louisville',
      'Columbus',
      'Cincinnati',
      'Dayton'
    ],
    showElevationSelect: true,
    kovaKeyMap: {
      elevation: 'ELEV-ELEV',
      garage: 'GARG-GARG',
      modelName: 'Name',
      numBeds: 'DescrBedrooms',
      numBaths: 'DescrBaths',
      numSqf: 'DescrSqf',
      optionText: 'Text'
    },
    theme: {
      logo: 'silverthorne_logo.jpg',
      logoColor: '#FFF',
      primaryColor: '#00274D',
      progressBarFillColor: '#00274D',
      viewNameTextTransform: 'uppercase',
      viewNameFontWeight: 600,
      viewToggleArrowImg: 'left_chevron.svg'
    }
  },arbornew: {
    defaultCommunityRID: 218,
    defaultModelRID: 7448,
    touchscreenEnabled: false,
    showPriceOnMenu: false,
    printToS3: false,
    showPriceOnPDF: false,
    allowFloorplanZoom: true,
    nestedUnderCommRidS3: true,
    colorSelectorEnabled: false,
    showKovaStage: true,
    loadModelRules: false,
    useKovaPDFDownloader: true,
    clientFloorOnAppLoad: null,
    multiChoiceOptExclusionList: [],
    showCommunityDropdownList: false,
    communityDropdownList: [
      278,
      287,
      281,
      280,
      283,
      293,
      303,
      292,
      305,
      302,
      304
    ],
    showCityDropdownList: true,
    cityDropdownList: [
      'Indianapolis',
      'Louisville',
      'Columbus',
      'Cincinnati',
      'Dayton'
    ],
    showElevationSelect: true,
    kovaKeyMap: {
      elevation: 'ELEV-ELEV',
      garage: 'GARG-GARG',
      modelName: 'Name',
      numBeds: 'DescrBedrooms',
      numBaths: 'DescrBaths',
      numSqf: 'DescrSqf',
      optionText: 'Text'
    },
    theme: {
      logo: 'arbor_logo.png',
      primaryColor: '#00274D',
      progressBarFillColor: '#00274D',
      viewNameTextTransform: 'uppercase',
      viewNameFontWeight: 600,
      viewToggleArrowImg: 'left_chevron.svg'
    },
  },oakwoodnew: {
    defaultCommunityRID: 219,
    defaultModelRID: 19288,
    touchscreenEnabled: false,
    showPriceOnMenu: false,
    printToS3: false,
    showPriceOnPDF: true,
    allowFloorplanZoom: true,
    nestedUnderCommRidS3: true,
    colorSelectorEnabled: false,
    showKovaStage: true,
    loadModelRules: false,
    useKovaPDFDownloader: true,
    clientFloorOnAppLoad: 0,
    multiChoiceOptExclusionList: ['REVERSEPLAN'],
    showCommunityDropdownList: false,
    communityDropdownList: [],
    showElevationSelect: true,
    kovaKeyMap: {
      elevation: 'ELEVATION',
      garage: 'GARAGE',
      modelName: 'MarketingName',
      numBeds: 'DescrBedrooms',
      numBaths: 'DescrBaths',
      numSqf: 'DescrSqf',
      optionText: 'Text'
    },
    theme: {
      logo: 'Oakwood_Stacked_White.png',
      primaryColor: '#000',
      viewNameTextTransform: 'capitalize',
      viewNameFontWeight: 500,
      viewToggleArrowImg: 'left_arrow.png',
      progressBarFillColor: '#086EBB'
    }
  }
};

export const ClientSettingsContext = createContext({});

export const ClientSettingsProvider = ClientSettingsContext.Provider;
export const ClientSettingsConsumer = ClientSettingsContext.Consumer;
